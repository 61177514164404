.wrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.close_icon {
    display: none;
    position: absolute;
    cursor: pointer;
    top: -7px;
    right: -7px;
}

.wrapper:is(:hover) .close_icon {
    display: block;
}

.wrapper > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    gap: 10px;
}

.wrapper > div > p {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 650;
    line-height: 140%;
    letter-spacing: -0.12px;
}

.wrapper > div > span {
    color: var(--text);
    font-size: 25px;
    font-style: normal;
    font-weight: 650;
    line-height: 140%;
    letter-spacing: -0.253px;
}

.mood_wrapper > div > span {
    color: unset;
    background: linear-gradient(98deg, #F79E1B 19.62%, #F75D1B 55.06%, #D537A8 84.43%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


/*chart*/

.chart_row {
    display: flex;
    flex-direction: row !important;
    gap: 16px;
    align-items: center;
}

.chart_amount {
    color: #000;
    font-size: 25px;
    font-style: normal;
    font-weight: 650;
    line-height: 140%; /* 35.424px */
    letter-spacing: -0.253px;
}

.chart_bottom_text {
    color: #57596B !important;
}

@media screen and (max-width: 1200px) and (min-width: 800px)  {
    .wrapper > img {
        width: 30px;
        height: 30px;
    }

    .wrapper > div > span {
        color: var(--text);
        font-size: 18px;
        font-style: normal;
        font-weight: 650;
        line-height: 140%;
        letter-spacing: -0.253px;
    }

    .chart_amount {
        font-size: 18px;
    }

    .wrapper > div > p {
        font-size: 10px;
    }

}