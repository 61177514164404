.paddings {
    width: 100%;
    padding-right: 6%;
    margin-bottom: 50px;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    background: var(--third-bg);
    box-shadow: var(--main-shadow);
    padding: 10px 20px 10px 10%;
    /* min-width: 100%; */
    border-radius: 0 10px 10px 0;

    gap: 20px;

    transition: all 0.4s 1s ease-in;
}

.wrapper .input_wrapper {
    background: white;
    border-radius: 10px;
    padding: 4px 16px 4px 5px;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;

    opacity: 1;

    transition: opacity 0.1s 1s ease-in;
}

.input_wrapper .input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 16px;
    line-height: normal;
    font-family: Futura, sans-serif;
    font-style: italic;
}

.input_wrapper .input:focus {
    border: 0;
    outline: 0;
}

.input_wrapper .input::placeholder {
    color: #B5B6AA;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
}

.close {
    margin-left: 30px;
    cursor: pointer;
}

.open {
    cursor: pointer;
    margin-left: 6px;
}

.mark_wrapper {
    width: 100%;
    padding-left: 13%;
    padding-right: 7%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    margin-bottom: 40px;

    max-height: 100%;
    overflow: auto;

    transition: max-height 1s ease-in;
}

.mark_wrapper > div {
    width: fit-content;
}

.mark {
    border-radius: 15px;
    color: white;
    background: linear-gradient(90deg, rgba(29, 150, 222, 1) 0%, rgba(8, 2, 103, 1) 52%, rgba(150, 0, 218, 1) 100%);
    padding: 11px 15px;

    font-style: italic;
    font-weight: 600;
    font-size: 15px;

    box-shadow: var(--main-shadow);

    text-align: left;
    max-width: 600px;

    animation: appear 0.6s ease-in-out;
    display: inline-block;
    position: relative;
}

.mark > a {
    text-decoration: underline;
}

.mark l-cardio {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.mark_user {
    background: var(--secondary-bg);
    color: var(--gray-text);
}

.note {
    font-weight: 400;
    font-style: italic;
    font-size: 11px;
    margin-top: 16px;
    color: #646363;

    padding-inline: 16px;
}

.note > span {
    color: var(--accent);
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media screen and (max-width: 800px) {
    .paddings {
        margin-right: 0;
        height: auto;
    }

    .wrapper {
        padding: 10px 20px 10px 2%;
        gap: 12px;
    }

    .input_wrapper .input {
        font-size: 16px;
    }

    .input_wrapper .input::placeholder {
        font-size: 16px;
    }

    .close {
        margin-left: 0;
        width: 20px;
    }

    .wrapper .input_wrapper {
        gap: 8px;
    }

    .mic_icon {
        width: 10px !important;
    }
}