.wrapper {
    height: 34px;
    width: 100%;
    background: var(--accent);
    
    display: flex;
    align-items: center;

    max-width: 100vw;
    overflow: hidden;
}

.wrapper .inner {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 81px;

    animation: swipe 100000ms linear infinite backwards;
}

.wrapper:hover .inner {
    animation-play-state: paused;
}

.wrapper .inner > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.wrapper p {
    color: white;
    text-wrap: nowrap;
    font-size: 11px;
    font-weight: 500;
    white-space: pre;
}

.wrapper a {
    border-radius: 4px;
    border: 0.5px solid white;
    text-wrap: nowrap;
    white-space: pre;

    padding: 4px 7px;
    color: white;

    font-size: 8px;
    font-weight: 600;
    transition: 0.3s ease;
}

.wrapper a:hover {
    background: white;
    color: var(--accent);
}



@keyframes swipe {
    from { transform: translate(0) }
    to { transform: translate(-100%) }
}