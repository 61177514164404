.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.input_white {
}

.wrapper label {
  color: #4f5b76;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.wrapper .error {
  color: #900;
}

.input {
  width: 100%;
  border-radius: 10px;
  background: var(--third-bg);
  border: none;

  padding: 10px 20px;

  caret-color: var(--gray-text);
  box-shadow: 0px 2.51px 5.01px 0px #00000012;
}

.input_white {
  width: 100%;
  border-radius: 10px;
  background: white;
  border: 2.5px solid;
  border-color: #e0e0e0;
  box-shadow: 0px 2.51px 5.01px 0px #00000012;

  padding: 10px 20px;

  caret-color: var(--gray-text);
}

.input_white:focus {
  outline: none;
}

.input:focus {
  outline: none;
  border: none;
}

.inputButton {
  height: 44px !important;
}

.note {
  color: var(--gray-text-secondary);
  font-style: italic;
}

@media screen and (max-width: 800px) {
  .input,
  .input_white {
    font-size: 14px;
  }
}
