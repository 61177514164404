.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  /* height: 100vh; */

  background: var(--main-bg);
  padding: 7% 8%;
}

.wrapper > div,
.wrapper > form {
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.wrapper > form > div {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .wrapper {
    padding: 0 8%;
  }

  .buttons button {
    width: 140px;
  }
}
