.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  max-width: 100vw;
}

.label,
.label_main_slider {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 200;

  color: #241426;
}

.label > p {
  color: white;

  margin-top: 30px;
  font-size: 28px;
  font-style: italic;
  font-weight: 450;
  line-height: 36px;
  text-align: left;
}

.paddings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  padding: 4% 7% 60px 7%;
  width: 100%;
}

.paddings_main_slider {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  padding: 4% 5% 60px 5%;
  width: 100%;
}

.texts > p {
  max-width: 80%;
  color: var(--text);
  font-size: 26px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  text-align: right;
}

.texts > span {
  color: #89216c;
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-right: 10%;

  align-self: flex-end;
}

.second_part {
  position: relative;
  width: 100%;
  background: linear-gradient(180deg, #c2bdca 0%, #a19aaf 69.26%, #dedbe4 100%);
  padding: 40px 7% 60px 7%;
  /* height: 100vh; */
}

.second_part .dark_label {
  color: var(--text);
  font-size: 48px;
}
.second_part .white_label {
  color: white;
  text-align: left;
  font-size: 16px;
  margin-bottom: 50px;
}

.second_part .button {
  border: 0;
  outline: 0;

  border-radius: 8px;
  background: var(--accent);

  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  padding: 8px 30px;
}

.second_part .button:focus {
  border: 0;
  outline: 0;
}

.second_part .calculator {
  align-self: flex-end;
  margin-top: -140px;
}

.calculator > span:is(:last-child) {
  color: white !important;
}

.second_part .products_image {
  align-self: center;

  margin-top: 30px;
}

.amount_button {
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  align-self: flex-end;

  right: 0;
  bottom: 30px;

  gap: 10px;
}

.amount_button .text {
  color: white;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.amount_button .offer {
  border-radius: 8px 0 0 8px;
  background: var(--accent);
  cursor: pointer;

  line-height: normal;
  font-style: normal;
  text-align: center;
  color: #fff;

  padding: 10px 40px;

  transition: background 0.3s ease;
}

.amount_button .offer:hover {
  background: var(--accent-hover);
}

.amount_button .offer .text1 {
  font-size: 41px;
  font-weight: 500;
}

.amount_button .offer .text2 {
  font-size: 20px;
  font-weight: 400;
}

.third_section_text {
  margin-top: 70px;

  width: 100%;
  color: var(--text);
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
}

.third_section_text > span {
  color: var(--accent);
}

.third_section_text > br {
  display: block;
  margin-top: 25px;
  content: '';
}

.doctors {
  padding-top: 0;
  max-width: 100vw !important;
}

.slider {
  margin-top: -9.8%;
  max-width: 100vw !important;
}

.offer:disabled {
  background: gray !important;
  cursor: not-allowed !important;
}

.offer .price_label {
  color: var(--icon-hover);
  transition: color 0.3s ease;
}
.offer:hover .price_label {
  color: white;
}

.cross {
  text-decoration: none;
  position: relative;

  color: var(--icon-hover);
  transition: color 0.3s ease;
}

.offer:hover .cross {
  text-decoration: none;
  position: relative;

  color: white;
}

.cross:before {
  content: '';
  border-bottom: 2px solid var(--icon-hover);
  position: absolute;
  width: 120%;
  height: 50%;
  margin-left: -4px;
  transform: rotate(-12deg);
  transition: border-bottom 0.3s ease;
}

.offer:hover .cross:before {
  content: '';
  border-bottom: 2px solid white;
  position: absolute;
  width: 120%;
  height: 50%;
  margin-left: -4px;
  transform: rotate(-12deg);
}

@media screen and (max-width: 1540px) {
  .second_part {
    padding: 4% 0% 60px 0%;
    height: unset;
  }
  .second_part .label {
    padding: 0 7% 0 7%;
  }

  .second_part .label_main_slider {
    padding: 0 7% 0 7%;
  }

  .amount_button .offer .text1 {
    font-size: 30px;
  }

  .amount_button .offer .text2 {
    font-size: 16px;
  }
}

@media screen and (max-width: 900px) {
  .second_part .calculator {
    margin-top: -80px;
  }
}

@media screen and (max-width: 800px) {
  .paddings {
    max-width: 100vw;
    /* padding: 20px; */
    padding: 20px 7% 20px 7%;
    align-items: center;
    justify-content: center;
  }
  .second_part .label_main_slider {
    padding: 0 4% 0 4%;
    width: 100%;
  }
  .label > p {
    font-size: 20px;
    line-height: 30px;
  }

  .paddings_main_slider {
    max-width: 100vw;
    padding: 20px;
    align-items: center;
    justify-content: center;
  }

  .second_part .calculator {
    margin-top: -140px;
  }

  .second_part .calculator {
    margin-top: 20px;
    max-width: calc(100vw - 40px);
  }

  .second_part .white_label {
    width: 100%;
  }

  .amount_button {
    /* align-items: flex-end; */
    bottom: 40px;
  }

  .amount_button .offer {
    padding: 8px 20px 8px 30px;
  }

  .amount_button .offer .text1 {
    font-size: 24px;
  }
  .amount_button .offer .text2 {
    font-size: 16px;
  }

  .third_section_text {
    margin-top: 20px;
    font-size: 18px;
  }

  .third_section_text > br {
    margin-top: 16px;
  }

  .amount_button {
    bottom: 60px;
  }

  .slider {
    margin-top: 0;
  }
}

.button {
  font-weight: 600 !important;
}

@media screen and (max-width: 1540px) {
  .button {
    font-size: 18px !important;
    width: 160px !important;
    padding: 6px 18px !important;
  }
}

@media screen and (max-width: 1200px) {
  .button {
    width: 120px !important;
    padding: 4px 8px !important;
    font-size: 16px !important;
  }
}

@media screen and (max-width: 800px) {
  .button {
    font-size: 12px !important;
    padding: 4px 4px;
    width: 100px !important;
    border-radius: 8px !important;
  }
}
