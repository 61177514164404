.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.wrapper label {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.wrapper .error {
  color: #900;
}

.select {
  width: 100% !important;
  min-width: 124px !important;
  border-radius: 10px !important;
  background: var(--third-bg) !important;
  border: none !important;

  padding: 10px 20px !important;

  caret-color: var(--gray-text) !important;
}

.select_white {
  width: auto;
  border-radius: 10px;
  background: white;
  border: none;

  padding: 10px 20px;

  caret-color: var(--gray-text);
}

.select_white:focus {
  outline: none;
  border: none;
}

.select:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.selectButton {
  height: 44px !important;
}

.note {
  color: var(--gray-text-secondary);
  font-style: italic;
}

.date {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  gap: 30px;
}

@media screen and (max-width: 800px) {
  .select,
  .select_white {
    padding: 6px 10px;
    font-size: 14px;
  }
  .selectButton {
    height: 33px !important;
  }
}
