.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-self: center;

    gap: 4rem;
    padding: 0 8% 60px 8%;
    max-width: calc(100vw - 60px);
    width: 100%;
}

.wrapper .label {
    color: var(--text);
    font-size: 68px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.68px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.wrapper .description {
    color: var(--gray-text);
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.wrapper .cards_wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    column-gap: 3%;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem 2rem;
    border-radius: 20px 100px 20px 20px;
    text-align: left;

    width: 100%;
    background: var(--text);

    position: relative;

    height: 40rem;
    overflow: hidden;
    gap: 1rem;
}
.card:after {
    position: absolute;
    bottom: -120px;
    left: 0;
    content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzOTgiIGhlaWdodD0iNTUiIHZpZXdCb3g9IjAgMCAzOTggNTUiIGZpbGw9Im5vbmUiPg0KICA8cGF0aCBvcGFjaXR5PSIwLjIyIiBkPSJNMjAuNDIgMEgzNzcuNDNDMzg4LjcgMCAzOTcuODUgOS4xNSAzOTcuODUgMjAuNDJWNTQuNzJIMFYyMC40MkMwIDkuMTUgOS4xNSAwIDIwLjQyIDBaIiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXJfMTA0Xzc1KSIvPg0KICA8ZGVmcz4NCiAgICA8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfMTA0Xzc1IiB4MT0iMTk4LjkyIiB5MT0iNTQuNzIiIHgyPSIxOTguOTIiIHkyPSIwIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+DQogICAgICA8c3RvcCBzdG9wLWNvbG9yPSJ3aGl0ZSIgc3RvcC1vcGFjaXR5PSIwIi8+DQogICAgICA8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiMyMzFGMjAiLz4NCiAgICA8L2xpbmVhckdyYWRpZW50Pg0KICA8L2RlZnM+DQo8L3N2Zz4=");
}

.start_card .label,
.card .label {
    color: #FFF;
    font-size: 51px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.card .labelSecond {
    color: var(--accent);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.start_card .description,
.card .description {
    color: #FFF;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 160.039%;
}

.start_card .divider,
.card .divider {
    width: 100%;
    height: 3px;
    background: white;
    border-radius: 1px;
}

.laptop {
    position: absolute;
    bottom: -12px;
    left: -12px;
}

.start_card {
    background: var(--accent);
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6rem 2rem 0.1rem 2rem;
    gap: 1rem;

    text-align: left;
    height: 40rem;
}

.small_label {
    font-size: 18px;
}

.start_card .button {
    margin: auto;
    width: 100%;
    padding: 0.5rem 0;
    border-radius: 18px;
    background: #FFF;
    color: #000;
    text-align: center;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .card,
    .start_card {
        height: 32rem;
    }
}

@media screen and (max-width: 1540px) {
    .wrapper {
        gap: 2rem;
        max-width: 100vw;
    }

    .wrapper .label {
        font-size: 40px;
    }

    .wrapper .description {
        font-size: 18px;
    }

    .wrapper .cards_wrapper {
        gap: 1%;
    }

    .start_card {
        height: 32rem;
    }
    .wrapper .cards_wrapper .card {
        height: 32rem;
        border-radius: 15px 70px 15px 15px;
    }

    .start_card .label,
    .card .label {
        color: #FFF;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .card .labelSecond {
        color: var(--accent);
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .start_card .description,
    .card .description {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160.039%;
    }

    .laptop {
        width: 80%;
    }
}

.shadow {
    width: 100%;
}
