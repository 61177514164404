@import url('https://fonts.cdnfonts.com/css/barlow-condensed?styles=18513,18511,18514');
@import url('https://fonts.cdnfonts.com/css/kredit-front');

html {
  --main-bg: #f3f1f1;
  --secondary-bg: #d7dadb;
  --third-bg: #d9d9d9;

  --messages-bg: #7c727d;

  --secondary-gray: #aeaeae;

  --footer: #241426;
  --text: #241426;
  --accent: #d537a8;
  --accent-hover: #89216c;
  --main-gradient: linear-gradient(90deg, #1d96de 0%, #080267 52%, #9600da 100%);
  --secondry-gradient: linear-gradient(105.96deg, #fcc188 0%, #7042d2 65.07%, #2e008e 100%);
  --secondry-gradient-row: linear-gradient(90deg, #fcc188 0%, #7042d2 65.07%, #2e008e 100%);

  --help-color: #4c82c2;
  --gray-text: #646363;
  --gray-text-secondary: #a4aaad;

  --main-shadow: 4px 4px 13px 0 rgba(0, 0, 0, 0.25);

  --color-ui-2: #1b2028;

  --icon: #89216c;
  --icon-hover: #89216c;

  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: futura-pt, 'sans-serif' !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--main-bg) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*:focus {
  box-shadow: none !important;
}

*[data-focus] {
  box-shadow: none !important;
}
