.wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    column-gap: 20px;
    width: 100%;
}

.card_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card_wrapper .head {
    width: 100%;
    text-align: left;
    border-radius: 4px 4px 0 0;
    border: 1px solid #ECECEC;
    background: #F8F8F8;

    padding: 13px 18px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card_wrapper .head .label {
    color: #2C2C2C;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.card_wrapper .body {
    width: 100%;
    border-radius: 0 0 4px 4px;
    border: 1px solid #ECECEC;
    background: white;
    text-align: left;

    padding: 20px 15px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card_wrapper .body .summary_table {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;

    padding-right: 17px;
}

.card_wrapper .body .text_p {
    font-size: 12px;
    color: #696969;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}

.card_wrapper .body .text_span {
    color: #2C2C2C;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.card_wrapper .body .progress_bar_wrapper {
    position: relative;
    overflow: hidden;

    width: 100%;

    border-radius: 4px;
    background: linear-gradient(98deg, #F79E1B 19.62%, #F75D1B 55.06%, #D537A8 84.43%);

    height: 17px;
}

.card_wrapper .body .progress_bar_wrapper .progress {
    position: absolute;
    background: #EAEAEA;
    height: 100%;
    right: 0;
    bottom: 0;
}

.card_wrapper .body .payment_method_wrapper {
    align-self: center;

    border-radius: 4px;
    border: 1px solid #ECECEC;
    background: #FFF;

    padding: 11px;


    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    gap: 15px;
    width: 100%;
}

.card_wrapper .body .payment_method_wrapper .card_logo {
    width: 50px;
    height: 40px;
}

.card_wrapper .body .payment_method_wrapper .col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    gap: 4px;
}

.card_wrapper .body .payment_method_wrapper .col span {
    color: #696969;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.card_wrapper .body .payment_method_wrapper .col p {
    color: #2C2C2C;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.card_wrapper .body .payment_method_wrapper .change_button {
    display: flex;
    width: 62px;
    height: 28px;
    padding: 7px 11px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    color: #696969;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border-radius: 4px;
    border: 1px solid #ECECEC;
    background: #FFF;

    margin-left: auto;

    outline: 0;
}

.card_wrapper .body .payment_method_wrapper .change_button:focus {
    outline: 0;
    border: 1px solid #ECECEC;
}

@media screen and (max-width: 1024px) {
    .wrapper {
        grid-template-columns: unset;
        row-gap: 20px;
    }
}

.button {
    outline: 0;
    border: 0;

    display: flex;
    padding: 8px 11px;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
    background: #89216C;

    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}