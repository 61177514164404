.icon_1, .icon_2, .icon_3 {
    position: absolute;
    top: 50%;
    width: 18px;
    height: 2px;
    background-color: white;
    transition: all 400ms cubic-bezier(.84,.06,.52,1.8);
}

.icon_1 {
    transform: translateY(-4px);
    animation-delay: 100ms;
}

.icon_3 {
    transform: translateY(4px);
    animation-delay: 250ms;
}

.hamburger_icon {
    position: relative;
    height: 15px;
    width: 18px;
    z-index: 1000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.icon_1.a {
    transform: rotate(40deg);
}
.icon_3.b {
    transform: rotate(-40deg);
}
.icon_2.c {
    opacity: 0;
}

.clear {
    clear: both;
}