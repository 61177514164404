.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 20px;
}

.label {
  color: var(--text);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.subtitle {
  color: #89216c;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  text-align: left;
}

@media screen and (max-width: 800px) {
  .label {
    font-size: 26px;
  }
}
