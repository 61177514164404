.wrapper {
    width: 100%;
}

.wrapper .table_header {
    width: 100%;
    text-align: left;
    border-radius: 4px 4px 0 0;
    border: 1px solid #ECECEC;
    background: #F8F8F8;

    padding: 13px 18px;
}

.wrapper .table_header .label {
    color: #2C2C2C;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.wrapper table {
    width: 100%;
    border-radius: 0 0 4px 4px;
    border: 1px solid #ECECEC;
    background: white;
    text-align: left;

    padding: 20px;
    overflow: scroll;
}

.wrapper table thead {
    font-size: 12px;
    color: #696969;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}
.wrapper table tbody td {
    padding: 5px 18px 0;
}
.wrapper table thead th {
    padding: 25px 18px 0;
}

.wrapper table tbody {
    color: #2C2C2C;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.wrapper table tbody tr td {
    padding-bottom: 13px;
}

.checkbox_wrapper {
    width: fit-content;
    color: #2C2C2C !important;
    font-size: 17px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    display: flex;
    align-items: flex-end !important;
    margin: 0;
}

.checkbox_wrapper span {
    width: 17px !important;
    height: 17px !important;

    margin: 0 !important;
    padding: 0 !important;

    right: -31px;
    top: 0;
    left: unset;

    border-radius: 3px;
}

.checkbox_wrapper span:after {
    left: 6px !important;
    top: 3px !important;
}

@media screen and (max-width: 800px) {
    .wrapper {
    }

    .table_scroll {
        /*width: 100%;*/
        overflow: scroll;
    }

    .checkbox_wrapper {
        padding-left: 0 !important;
        margin-right: 20px;
    }

    .checkbox_wrapper span {
        right: -22px;
    }

    .wrapper table tbody td:is(:last-child) {
        padding-left: 0;
    }
}