.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  padding: 7% 8% 60px 8%;
}

.wrapper_payment {
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  margin: 0 auto;
}
.wrapper_icon {
  background: var(--accent);
  padding: 20px 15px;
  border-radius: 20px;
  border: none;
}

.wrapper_icon_gray {
  background: #999;
  padding: 20px 15px;
  border-radius: 20px;
  border: none;
}

.wrapper_payment > .payment_label {
  font-size: 32px;
  font-weight: 800;
  text-align: center;
}

.wrapper_payment > .payment_sublabel {
  font-size: 24px;
  font-weight: 800;
  text-align: center;
}

.wrapper_payment > .payment_body {
  font-size: 24px;
  font-weight: 600;
  color: #646363;
  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.wrapper_payment > .payment_body.nowrap {
  display: block;
}

.wrapper_payment > .payment_body .link {
  color: #1d96de;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  align-self: center;
}

.buttons button {
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 9px 20px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons button:focus {
  border: none;
  outline: none;
}

.back {
  background: var(--secondary-gray);
  color: white;
  transition: all 0.3s ease;
}

.back:hover {
  background: var(--gray-text);
}

.continue {
  background: var(--accent);
  color: white;
  transition: all 0.3s ease;
}

.continue:hover {
  background: var(--accent-hover);
}

.href {
  color: var(--help-color);
}

@media screen and (max-width: 800px) {
  .wrapper_payment {
    width: auto;
  }
}
