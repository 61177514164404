.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
}

.second_label {
    font-weight: 600;
    font-size: 34px;
    line-height: 44px;

    color: var(--accent);
}

.paddings {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 7% 8% 60px 8%;
    overflow: hidden;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    font-style: normal;
    line-height: normal;
    font-weight: 500;
}

.header .label {
    color: var(--text);
    font-size: 37px;
    font-weight: 700;
    letter-spacing: -0.68px;
}

.header .description {
    color: var(--gray-text);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.21px;
}

.product {
    display: grid;
    grid-template-rows: 1fr;

    grid-template-columns: repeat(2, auto);

    column-gap: 10%;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2rem 3.1rem 5rem 3.1rem;
    border-radius: 20px 100px 20px 20px;
    text-align: left;

    width: 100%;
    max-width: 400px;
    background: var(--text);

    position: relative;

    height: auto;
    overflow: hidden;
    gap: 1rem;
}
.card:after {
    position: absolute;
    bottom: -120px;
    left: 0;
    content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzOTgiIGhlaWdodD0iNTUiIHZpZXdCb3g9IjAgMCAzOTggNTUiIGZpbGw9Im5vbmUiPg0KICA8cGF0aCBvcGFjaXR5PSIwLjIyIiBkPSJNMjAuNDIgMEgzNzcuNDNDMzg4LjcgMCAzOTcuODUgOS4xNSAzOTcuODUgMjAuNDJWNTQuNzJIMFYyMC40MkMwIDkuMTUgOS4xNSAwIDIwLjQyIDBaIiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXJfMTA0Xzc1KSIvPg0KICA8ZGVmcz4NCiAgICA8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfMTA0Xzc1IiB4MT0iMTk4LjkyIiB5MT0iNTQuNzIiIHgyPSIxOTguOTIiIHkyPSIwIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+DQogICAgICA8c3RvcCBzdG9wLWNvbG9yPSJ3aGl0ZSIgc3RvcC1vcGFjaXR5PSIwIi8+DQogICAgICA8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiMyMzFGMjAiLz4NCiAgICA8L2xpbmVhckdyYWRpZW50Pg0KICA8L2RlZnM+DQo8L3N2Zz4=");
}

.card .label {
    color: #FFF;
    font-size: 51px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.card .labelSecond {
    color: var(--accent);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.card .description {
    color: #FFF;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 160.039%;
}

.card .divider {
    width: 100%;
    height: 3px;
    background: white;
    border-radius: 1px;
}

.card .price {
    color: #FFF;
    font-size: 51px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin-block: 70px;
}

.card .button {
    align-self: center;
}

.card_shadow {
    width: 100%;
    max-width: 400px;
}

.product_item {
    align-self: center;
    margin-bottom: -50px;
}

.product_item .description {
    color: var(--help-color);
    text-align: center;
    font-size: 24px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
}

.audits {
    width: 80%;
    align-self: center;
}


.doctors {
    padding: 0 7%;
    max-width: 100vw !important;
    align-items: flex-start;
}

@media screen and (max-width: 1540px) {

    .header {
        gap: 10px;
    }

    .header .description {
        font-size: 16px;
    }

    .card {
        max-width: 360px;
        padding: 1.8rem 2rem 4.4rem 2rem;
        border-radius: 18px 80px 18px 18px;
    }

    .card .label {
        color: #FFF;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .card .labelSecond {
        color: var(--accent);
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .card .description {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160.039%;
    }

    .card .price {
        font-size: 36px;
        margin-block: 40px;
    }

    .card_shadow {
        max-width: 360px;
    }

    .product_item .description {
        font-size: 20px;
    }

}

