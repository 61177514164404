.label {
  text-align: left;
  margin-bottom: 40px;
}

.section {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;

  gap: 65px;
}

.item {
  padding: 0 66px 24px 66px;
  width: 470px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 20px;
}

.item > p {
  font-weight: 700;
  font-size: 23px;
  line-height: 34px;
}

.item > span {
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
}

.section > img {
  object-fit: contain;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.bottom > .logos > p {
  font-weight: 450;
  font-style: italic;
  font-size: 10px;
  line-height: 12px;
  color: #6f6f6f;

  text-align: left;
  margin-bottom: 20px;
}

.bottom > .logos > p.trust_box_label {
  text-align: center;
  margin-left: 0;
}

.indicator {
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  background: var(--secondary-gray);
  border-radius: 50%;
  margin-inline: 5px;
  cursor: pointer;
}

.indicator.active {
  background: var(--help-color);
}

.bottom_indicators {
  display: flex;
}

@media screen and (max-width: 800px) {
  .section {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
  }

  .bottom {
    flex-direction: column;
  }

  .section > img {
    object-fit: contain;
  }
  .item {
    padding: 0 0px 24px 0px;
  }
}

@media screen and (max-width: 1400px) {
  .section {
    gap: 30px;
  }
  .item {
    padding: 0 24px 24px 24px;
    width: 360px;
  }
}

@media screen and (max-width: 1200px) {
  .section {
    gap: 10px;
  }
  .item {
    padding: 0 8px 24px 8px;
    width: 240px;
  }
}
