.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 44px;
}

.second_label {
  color: #89216c;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  text-align: left;
  margin-top: -36px;
}

.paddings {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
  padding: 7% 8% 60px 8%;
}

.colored_text {
  color: var(--accent);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.wrapper .text {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 72px;
  width: 100%;
  align-self: center;
  justify-content: space-between;

  text-align: justify;
  line-height: normal;

  font-size: 24px;
  font-weight: 500;
  color: var(--text);

  font-style: italic;

  padding-inline: 18px;
}

.wrapper .text .col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.wrapper .text .col > br {
  display: block;
  content: '';
  margin-top: 20px;
  width: 10px;
}

.text > li {
  cursor: pointer;
}

.accent {
  color: var(--accent);
}

.click {
  color: var(--accent);
  text-decoration: underline 0.1em;
  text-decoration-color: rgb(213, 55, 168);

  cursor: pointer;
}

.bottom_text {
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--text);

  width: 100%;
  text-align: left;

  font-size: 18px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.bottom_text > span {
  padding-top: 24px;
  font-style: italic;
}

.button {
  margin-right: 90px;

  color: #fff;
  text-align: center;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  padding: 9px 12px;

  border-radius: 10px;
  background: var(--accent);

  align-self: flex-end;

  border: 0;
  outline: 0;
}

.button:focus {
  border: 0;
  outline: 0;
}

.subItems {
  margin-top: 41px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  text-align: justify;
  gap: 10px;

  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 26px;
  color: var(--text);
}

.subItems > li {
  display: grid;
  grid-template-columns: 12px auto;
  gap: 8px;
}

.subItems > li > svg {
  width: 12px;
  height: 12px;
  margin-top: 6px;
}

.subItems > li::marker {
  content: '';
}

.bottom_items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.bottom_items > .item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}

.bottom_items > .item > .lable {
  font-weight: 600;
  font-size: 34px;
  line-height: 44px;
  color: var(--accent);
}

.bottom_items > .item > .cols {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 90px;
}

.bottom_items > .item .texts {
  font-weight: 500;
  font-style: italic;
  font-size: 16px;
  line-height: 25px;
  color: var(--text);
  text-align: left;
}

@media screen and (max-width: 800px) {
  .paddings {
    width: 100%;
    padding: 7% 8% 60px 8%;
    gap: 30px;
  }

  .wrapper {
    grid-template-columns: 1fr;
  }

  .wrapper .text {
    grid-template-columns: 1fr;
    font-size: 16px;
    gap: 0;
  }

  .bottom_text {
    font-size: 16px;
  }

  .wrapper .text .col {
    width: 100%;
  }

  .wrapper .text .col > br {
    margin-top: 10px;
  }

  .bottom_items > .item > .cols {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}
