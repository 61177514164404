.form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  flex-wrap: nowrap;
  gap: 1rem;
  margin: 2rem 0;
  width: 100%;
  max-width: 420px;
}

.row {
  display: grid !important;
  grid-template-columns: 1fr !important;
  column-gap: 10px;
  padding-inline: 0 !important;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}

.row {
  display: grid !important;
  grid-template-columns: 1fr !important;
  column-gap: 10px;
  padding-inline: 0 !important;
}

.column {
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
  column-gap: 10px;
  padding-inline: 0 !important;
}

.column > div > input {
  width: 50% !important;
}

.stripe {
  width: 100%;
}
