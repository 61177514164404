.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    padding: 7% 8% 60px 8%;
    width: 100%;
}

.info {
    color: var(--gray-text);
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.disclaimer {
    color: var(--accent);
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.text_wrapper {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;

    gap: 20px;

    color: #241426;
    font-size: 21px;
    font-style: normal;
    line-height: normal;
}

.text_wrapper p {
    font-weight: 600;
    /*    todo replace   */
}