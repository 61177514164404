.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  margin-bottom: 50px;
  padding: 7% 8% 60px 8%;
  width: 100%;
}

/* item */

.items_wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 20px;
}

.item_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  /* border-bottom: 1px solid #021d64; */

  padding: 50px 0px 0px 50px;
  gap: 20px;
}

.item_wrapper .label_product {
  color: var(--accent-hover);
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  text-align: left;
  margin-left: -50px;
  margin-bottom: 10px;
}

.item_wrapper .label_secondary {
  color: var(--accent-hover);
  font-weight: 600;
  font-size: 22px;
  line-height: 31px;
  text-align: left;
}

.item_wrapper .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 90%;
}

.item_wrapper .row .image {
  background: var(--third-bg);
  padding: 60px 40px 40px 40px;
  border-radius: 10px;
  height: 200px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item_wrapper .row .image > img {
  max-height: 160px;
  object-fit: contain;
}

.item_wrapper .row .amount {
  height: 80%;

  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  gap: 20px;

  width: 30%;
}

.item_wrapper .row .amount .label {
  color: var(--text);
  font-size: 26px;
  font-style: normal;
  line-height: normal;
  font-weight: 400 !important;
}

.checkbox_label {
  font-size: 15px;
  text-align: left;
  max-width: 220px;
}

.item_wrapper .row .amount .label > span {
  font-weight: 600 !important;
}

.item_wrapper .row .amount .quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  /*background: #FFF;*/

  height: 27px;

  gap: 24px;
}

.item_wrapper .row .amount .quantity > div {
  border-radius: 7px;
  background: var(--third-bg);

  width: 27px;
  height: 27px;
  display: grid;
  place-content: center;

  cursor: pointer;
  user-select: none;
}

.item_wrapper .row .amount .quantity > span {
  font-weight: 700;
}

.item_wrapper .row .price {
  height: 80%;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item_wrapper .row .price .label {
  color: var(--text);
  font-size: 26px;
  font-style: normal;
  line-height: normal;
  font-weight: 400 !important;
  text-align: right;
}

.item_wrapper .row .price .amount_label {
  color: var(--text);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: right;
}

/*total*/

.total_wrapper {
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(25% + 200px);
  margin-left: calc(50% - 20px);

  color: var(--text);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.total_wrapper .text_row {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*buttons*/

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  align-self: center;
}

.buttons button {
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 9px 20px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.buttons button:focus {
  border: none;
  outline: none;
}

.back {
  background: var(--secondary-gray);
  color: white;
  transition: all 0.3s ease;
}

.back:hover {
  background: var(--gray-text);
}

.continue {
  background: var(--accent);
  color: white;
  transition: all 0.3s ease;
}

.continue:hover {
  background: var(--accent-hover);
}

.discount {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 2px;

  color: #f13a3b;
}

.cross {
  text-decoration: none;
  position: relative;

  color: var(--gray-text);
}

.cross:before {
  content: '';
  border-bottom: 2px solid #f13a3b;
  position: absolute;
  width: 100%;
  height: 50%;
  transform: rotate(12deg);
}

.discount > img {
  width: 20px;
  height: 20px;

  margin-top: -8px;
}

.href {
  color: var(--help-color);
}

.href_main {
  color: var(--accent);
}

@media screen and (max-width: 800px) {
  .total_wrapper {
    padding: 0;
    font-size: 22px;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    margin-left: 0;
  }

  .item_wrapper {
    padding: 0;
  }

  .item_wrapper .label_product {
    font-size: 22px;
    margin-left: 0px;
  }

  .item_wrapper .row {
    flex-wrap: wrap;
    width: 100%;
  }

  .item_wrapper .row .image {
    width: 100%;
  }

  .item_wrapper .row .amount {
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    height: unset;
    align-items: center;
  }

  .item_wrapper .row .price {
    flex-direction: row;
    width: 100%;
    height: unset;
  }

  .total_wrapper .text_row {
    white-space: nowrap;
  }

  .discount {
    margin-left: 10px;
  }
}
