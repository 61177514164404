.wrapper {
    border-radius: 27px;
    padding: 42px;
    background: linear-gradient(98deg, #8C236E 19.62%, #8C236F 55.06%, #D537A8 84.43%);
    position: relative;
    width: fit-content;
}

.wrapper_logged_in {
    border-radius: 27px;
    padding: 42px;
    background: linear-gradient(106deg, #080267 0%, #9600DA 65.07%, #080267 100%);
    position: relative;
    width: fit-content;
}

@media screen and (max-width: 800px) {
    .wrapper_logged_in {
        padding: 25px;
    }
    .wrapper {
        padding: 25px;
    }
}

.text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;

    font-size: 32px;
    padding: 10px 30px;
}

@media screen and (max-width: 800px) {
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 84vw;
        max-height: 84vw;
        aspect-ratio: 1/1;

        background: linear-gradient(90deg, rgba(29, 150, 222, 1) 0%, rgba(8, 2, 103, 1) 52%, rgba(150, 0, 218, 1) 100%);
    }

    .wrapper > svg {
        width: auto;
        height: auto;
        aspect-ratio: 1/1;
    }
}