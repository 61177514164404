.doctor_wrapper {
  /* width: fit-content; */

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 62px;
}

.doctor_wrapper > img {
  width: 288px;
  height: 288px;
}

.doctor_wrapper .texts {
  text-align: center;
  width: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  align-self: center;
  padding-bottom: 60px;
  max-width: 50%;

  gap: 40px;
}

.doctor_wrapper .texts > p {
  color: var(--text);
  font-style: italic;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  font-weight: 500;
}

.doctor_wrapper .texts > span {
  color: #89216c;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: right;
  width: 100%;
}

.indicator {
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  background: var(--secondary-gray);
  border-radius: 50%;
  margin-inline: 5px;
  cursor: pointer;
}

.indicator.active {
  background: var(--help-color);
}

.bottom_indicators {
  display: flex;
}

@media screen and (max-width: 800px) {
  .bottom_indicators {
    margin-top: 40px;
  }

  .doctor_wrapper {
    flex-direction: column;
    align-items: center;

    gap: 20px;
  }

  .doctor_wrapper .texts {
    text-align: left;
    padding: 0;
    display: block;
    max-width: 100%;
    padding: 0 7% 20px 7%;
  }

  .doctor_wrapper .texts > p {
    max-width: unset;
    font-size: 20px;
    line-height: 30px;
    padding-right: 0;
  }

  .doctor_wrapper .texts > span {
    font-size: 16px;
  }

  .doctor_wrapper > img {
    max-width: calc(100vw - 40px);
  }
}
