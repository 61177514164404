.wrapper {
  border-radius: 13px;
  width: 104px;
  height: 41px;
  background: var(--main-gradient);
  display: grid;
  place-content: center;
  padding: 4px 3px 4px 3px;
}

.small {
  width: 50px;
  height: 20px;
  border-radius: 6px;
}

.small > img {
  width: 39px !important;
  height: 13px !important;
}

.wrapper:focus {
  border: 0;
  outline: 0;
}

.wrapper > img {
  object-fit: contain;
  width: 87px;
  height: 30px;
}
@media screen and (max-width: 800px) {
  .wrapper > img {
    width: 46px;
  }
}
