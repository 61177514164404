.indicator {
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    background: var(--secondary-gray);
    border-radius: 50%;
    margin-inline: 5px;
    cursor: pointer;
}

.indicator.active {
    background: var(--help-color);
}

.slide_item_doctors {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    padding-block: 3rem;
}

.slide_item_doctors img {
    width: 40vw;
    object-fit: contain;
}

.slide_item_doctors span {
    color: var(--accent-hover);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.slide_item_doctors p {
    text-align: left;
    font-weight: 600;
}

.slide_item_doctors a {
    color: var(--help-color);
    font-size: 19px;
    font-style: italic;
    font-weight: 450;
    line-height: 140%;
    text-decoration-line: underline;
}

.bottom_wrapper_doctors {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 50px;
}

.bottom_wrapper_doctors > span {
    align-self: flex-start;
    justify-self: center;
}

.bottom_indicators {
    width: 100%;
    height: 1px;
    background: #021D64;
    position: relative;
    overflow: visible;
}

.bottom_indicator_item {
    height: 3px;
    position: absolute;
    background: #021D64;
    border-radius: 10px;
    bottom: -1px;

    transition: all 1s ease-in-out;
}

.header_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.header_info .description {
    color: var(--gray-text);
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.header_info .links {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.header_info .links a:first-child {
    background: var(--accent);
    color: white;
    padding: 6px 10px;
    border-radius: 10px;
}

.header_info .links a:last-child {
    color: var(--accent);
    padding: 6px 10px;
    border-radius: 10px;
}

@media screen and (max-width: 1540px) {
    .header_info .description {
        color: var(--gray-text);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}