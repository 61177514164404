.wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    column-gap: 25px;

    justify-content: center;
}

@media screen and (max-width: 800px) {
    .wrapper {
        grid-template-columns: 1fr;
        row-gap: 20px;
    }
}

.item {
    width: 100%;
    border-radius: 10px;
    height: 190px;

    text-align: left;
    padding: 0px 20px;
}

.item .top_text {
    font-size: 70px;
    font-weight: 600;
}

.item .bottom_text {
    font-weight: 500;
}

.item .bottom_with_top {
    font-size: 17px;
    width: 60%;
    margin-top: -20px;
}

.item .bottom_without_top {
    margin-top: 20px;
    width: 80%;
}

.notice_wrapper {
    display: flex;
    flex-direction: column;

    font-size: 10px;
    color: #AEAEAE;

    width: 100%;
    align-items: flex-end;
    justify-content: center;
}