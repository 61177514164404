.label {
    color: #89216C;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.68px;
    display: flex;
    align-items: center;
}

.second_label {
    color: #89216C;
    font-weight: 600;
    font-size: 34px;
    line-height: 31px;
    text-align: left;
    margin-top: -36px;
}

.img {
    user-select: none;
    align-self: center;
}

.wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 32px;

    padding-inline: 8%;
}

.wrapper > .text_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
}

.step {
    font-size: 32px;
    font-weight: 600;
    color: var(--accent);
    margin-top: 10px;
}

.label_first {
    color: var(--help-color);
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    margin-top: 2px;
}

.label_second {
    color: var(--text);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    margin-top: 8px;
}

.bottom_texts {
    width: 100%;

    column-gap: 70px;

    display: grid;
    grid-template-columns: 1fr 1.5fr;
}
.bottom_texts > div > p {
    text-align: left;
    color: var(--text);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.bottom_texts > div:is(:first-child) {
    display: flex;
    flex-direction: column;
    gap: 34px;
}

.bottom_texts > div > p > span {
    color: var(--help-color);
}

.bottom_texts > div > p:first-child {
    color: var(--accent);
}

.bottom_texts > div:is(:last-child) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 34px;
}

.part {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
}

.part > img {
    max-width: 130px;
}

.part .texts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    text-align: left;
}

.part .texts > p {
    color: var(--accent);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.part .texts > span {
    color: var(--text);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.percent_bar_wrapper {
    display: flex;
    align-items: center;

    gap: 15px;
}

.percent_bar {
    border-radius: 10px;

    padding: 10px 26px;

    font-size: 22px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    color: white;
}

.percent_bar_wrapper > div:is(:last-child) {
    color: var(--gray-text);
    font-size: 22px;
    font-style: normal;
    font-weight: 450;
    line-height: 140%;
}

.percent_bar_wrapper span {
    font-weight: 900;
}

.effectivness_label {
    color: var(--accent);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 21px;
}

.effectivness_note {
    color: #AEAEAE;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    align-self: flex-end;
}

.effectivness {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 12px;
}

.effectivness > span {
    color: #89216C;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 131%;
}

@media screen and (max-width: 400px) {
    .wrapper {
        gap: 60px !important;
    }
}
@media screen and (max-width: 800px) {
    .bottom_texts {
        grid-template-columns: 1fr;
    }

    .bottom_texts > div:is(:first-child) {
        max-width: 100%;
    }

    .percent_bar_wrapper {
        gap: 8px;
    }

    .percent_bar {
        font-size: 14px;
    }

    .percent_bar_wrapper > div:is(:last-child) {
        font-size: 14px;
    }

    .percent_bar_wrapper:is(:last-child) .percent_bar {
        width: unset !important;
    }

    .wrapper {
        grid-template-columns: 1fr;
        gap: 90px;
    }

    .part {
        max-width: fit-content;
    }

    .part:is(:first-child) {
        margin-top: 20px;
    }

    .part:is(:nth-child(2)) {
        margin-top: 20px;
    }

    .part > img {
        max-width: 40px;
    }

    .part > .texts > p {
        font-size: 16px;
    }

    .part > .texts > span {
        font-size: 12px;
    }

    .effectivness > span {
        font-size: 16px;
    }

    .effectivness > img {
        width: fit-content;
        max-width: 46vw;
    }

    .bottom_texts > div:is(:last-child) {
        gap: 10px;
    }
}

.doctors {
    max-width: 100vw;
}