.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  padding: 7% 8% 2% 8%;
  background: var(--main-bg);
}

.wrapper > div {
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.buttons_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
}

.wrapper button {
  border-radius: 20px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.social_button {
  display: flex;
  gap: 40px;
}

.facebook_button {
  background: #4a66ad;
  border-radius: 30px;
  width: 100%;
  color: white;
}

.other_social_button {
  background: white;
  border: 1px solid black;
  position: relative;
}

.other_social_button span {
  position: absolute;
  left: 0;
  right: 0;
  font-weight: 600;
}

.blue_button {
  background: #4064ea;
  color: white;
  justify-content: center !important;
}

.wrapper a {
  text-decoration: underline;
}

.input {
  width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid var(--secondary-bg);
}

.input:focus {
  outline: none;
}

.wrapper p,
.wrapper a,
.wrapper span {
  color: var(--messages-bg);
}

.blue_text {
  color: #769ece !important;
}

.label {
  color: var(--text) !important;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.form {
  width: 400px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  gap: 40px;
}
.form .error {
  color: #900;
}
.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.buttons button {
  border-radius: 20px;
  border: none;
  outline: none;
  padding: 9px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons button:focus {
  border: none;
  outline: none;
}

.back {
  background: var(--secondary-gray);
  color: white;
  transition: all 0.3s ease;
}

.back:hover {
  background: var(--gray-text);
}

.continue {
  background: var(--accent);
  color: white;
  transition: all 0.3s ease;
}

.continue:hover {
  background: var(--accent-hover);
}

.continue:disabled,
.continue[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.resendCode {
  font-size: 12px;
  color: var(--accent);
  margin-top: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  color: #e0b9cc;
}

@media screen and (max-width: 800px) {
  /* .wrapper {
        padding: 20px;
    } */

  .wrapper form {
    width: 100%;
  }

  .form {
    width: 100%;
  }

  .buttons button {
    width: 100%;
  }
}
