.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 7% 0 60px 0;
  align-self: center;

  max-width: 84vw;
}

.wrapper .label {
  color: var(--text);
  font-size: 68px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.68px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  align-self: flex-start;
}

.wrapper .description {
  color: var(--accent);
  font-size: 29px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  align-self: flex-start;
}

.doctors {
  padding: 0 50px;
  max-width: 100vw !important;
}

.price_label {
  color: var(--icon-hover);
}

.cross {
  text-decoration: none;
  position: relative;

  color: var(--icon-hover);
}

.cross:before {
  content: '';
  border-bottom: 2px solid var(--icon-hover);
  position: absolute;
  width: 100%;
  height: 50%;
  margin-left: -4px;
  transform: rotate(-12deg);
  transition: border-bottom 0.3s ease;
}

.insert_text p {
  text-align: left;
  line-height: 28px;
  /* font-weight: 600; */
}

.insert_text ul {
  padding-left: 20px;
}

.button {
  border: 1px solid var(--accent);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;

  align-self: self-end;
  transition: 0.3s ease;

  padding: 7px 14px;
  width: 128px;

  outline: 0;
}

.button:disabled {
  border: 1px solid transparent;
  cursor: not-allowed;
}

.button:focus {
  outline: 0;
}

.button[variant='contain'] {
  background: var(--accent);
  color: white;

  &:hover {
    background: #89216c;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    max-width: 100vw;
  }
}
@media screen and (max-width: 1540px) {
  .wrapper .label {
    font-size: 35px;
  }

  .wrapper .description {
    font-size: 18px;
    width: 100%;
  }
}
