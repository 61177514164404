@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.arrow {
  transition: all 0.3s linear;
  transform: rotate(0);
  margin: -10px;
}

.arrow_rotate {
  transform: rotate(180deg);
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width: 100%;
  gap: 6px;
}

.wrapper label {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.wrapper .error {
  color: #900;
}
.label {
  /* background: var(--secondary-bg); */
  padding: 10px 20px;
  color: var(--text);
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
  height: 49px;

  background: white;
  border: 2.5px solid;
  border-color: #e0e0e0;
  box-shadow: 0px 2.51px 5.01px 0px #00000012;

  width: 100%;
  transition: background-color 0.3s ease;
  z-index: 2;
}

.label:hover {
  background: var(--gray-text-secondary);
}

.list {
  position: absolute;
  animation: appear 0.3s forwards;
  background: var(--secondary-bg);
  color: var(--gray-text);
  top: 38px;
  left: 0;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  gap: 9px;
  font-size: 14px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.35);
  /* background: white;
  border: 2.5px solid;
  border-color: #e0e0e0;
  box-shadow: 0px 2.51px 5.01px 0px #00000012; */

  max-height: 400px;
  overflow-y: auto;
  z-index: 3;
}

.list_item {
  padding: 10px 15px;
  display: grid;
  grid-template-columns: 60px 20px 14px;
  gap: 6px;
  place-content: center;
  align-items: center;
}

.disabled_list_item {
  opacity: 0.5;
  cursor: default;
  user-select: none;
  pointer-events: none;
}

.list_item span {
  text-align: left;
}

.list_item .active {
  color: var(--accent);
}

.list_item:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.list_item:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.list_item:hover {
  cursor: pointer;
  color: white;
  background: var(--secondary-gray);
}

.list_item img {
  border-radius: 50%;
}
