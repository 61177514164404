.col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 200px;

    gap: 20px;
}

.typewriter {
    display: flex;
    justify-content: center;
}

.typewriter h1 {
    overflow: hidden;
    border-right: .15em solid var(--accent);
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: .15em;
    animation:
            typing 1.5s steps(20, end),
            blink-caret .75s step-end infinite;

    font-size: 28px;
}

@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: var(--accent); }
}