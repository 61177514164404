.mt20 {
    margin-top: 20px;
}

.mb20 {
    margin-bottom: 20px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    padding: 7% 6% 60px 6%;
    width: 100%;
    height: 100%;
}

.wrapper_loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.layout {
    width: 100%;
    display: grid;

    column-gap: 20px;

    grid-template-columns: max-content auto 22%;
}

.layout_two {
    grid-template-columns: max-content auto;
}

.charts {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    gap: 20px;
}

.chart_data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}

.chart_name {
    color: black;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.219px;
}

.chart_amount {
    color: black;
    font-size: 51px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.511px;
}

.chart_percent {
    width: 12px;
    height: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.219px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.chart_green {
    color: #007400;
}
.chart_yellow {
    color: #F79E1B;
}

.chart_bottom_text {
    color: #57596B;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.981px */
    letter-spacing: -0.164px;
}

.chart_percent_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.main_data_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.main_data_journey {
    width: 100%;
    height: 100%;
    position: relative;
}

.main_data_journey .dashed {
    border-right: 4px dashed #89216C;
    margin-left: 41px;
    height: 100%;
    width: 0;
    position: absolute;
    z-index: -1;
}

.journey_streak {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    justify-content: space-around;
    height: 100%;
}

.main_banners_wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 20px;
}

.main_banners_wrapper > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.main_banners_wrapper > div:last-child > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main_banners_left_text {
    color: var(--text);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 90%;
    letter-spacing: -1.28px;
}

.main_banners_left_text_small {
    color: #646363;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 140%;
    letter-spacing: -0.292px;
}


/* reuse */

.item_bg {
    width: 100%;
    border-radius: 20px;
    background: #FFF;
    min-height: 80px;
    box-shadow: 4px 4px 7px 0 rgba(0, 0, 0, 0.12);

    padding: 1rem;
}

/*pages*/

.page_wrapper {
    width: 100%;
    padding: 0 2% 60px 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

/*userPage*/

.userPage {
    gap: 42px;
    padding-inline: 2% !important;
}

.userPage_avatar {
    position: relative;
    width: 145px;
    height: 145px;
    padding: 4px;

    border-radius: 50%;
    background: var(--secondry-gradient);

    filter: drop-shadow(6px 6px 10px rgba(56, 77, 108, 0.25));
}

.userPage_avatar .avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.userPage_avatar .upload {
    position: absolute;
    right: 10px;
    bottom: 10px;

    width: 26px;
    height: 26px;
}

.userPage_avatar .upload img {
    cursor: pointer;
}


.userPage_avatar .upload input {
    display: none;
}

.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.col {
    width: 100%;
    height: auto;

    gap: 10px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.userPage_username {
    color: var(--text);
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.userPage_label {
    color: var(--text);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.userPage_text {
    color: var(--text);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.userPage_text_accent {
    color: var(--accent);
}

/*returns page*/

.billingPage .text,
.returnsPage .text {
    width: 100%;
    text-align: left;
    color: var(--gray-text);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin-top: 20px;
}

.billingPage .text br,
.returnsPage .text br {
    display: block;
    margin-top: 20px;
    content: '';
    width: 10px;
}

.billingPage .text a,
.returnsPage .text a {
    color: var(--accent);
}

.form_wrapper {
    height: 700px !important;
    overflow-y: scroll;
    padding-left: 22px;
    min-height: unset !important;
}

.form_wrapper > div:is(:first-child)::before {
    /* content:"\A"; */
    /* border-style: solid;
    border-width: 6px;
    border-color: transparent transparent #B12B8B #B12B8B;
    position: absolute;
    background: white;
    width: 40px;
    height: 40px;
    left: -20px;
    transform: rotate(45deg); */
    /* transform: rotate(45deg) skew(-0.06turn, 18deg); */
    /* top: 0; */
    /* bottom: 0; */
    /* margin-block: auto; */
    /* z-index: 10; */
}

.form_wrapper > div:is(:nth-child(3)) {
    display: none;
}

.form_wrapper > div:is(:first-child) {
    background: white;
    border-radius: 12px;
    border-left: 6px solid #B12B8B;
    overflow-y: scroll;
}

.form_wrapper > div:is(:first-child) > div:is(:first-child) > div:is(:last-child) {
    padding-bottom: 40px;
}

.active_item {
    scale: 1.2;
    position: relative;
}

/* .active_item::after {
    content: "";
    position: absolute;
    width: 100px;
    top: 0;
    bottom: 0;
    margin-block: auto;
    height: 5px;
    background: #B12B8B;
} */

.journey_item_bg_img {
    position: absolute;
    width: 80px;
    max-width: unset;
    height: 140%;
    left: 50%;
    top: 42%;
    transform: translate(-50%,-50%);
    z-index: -1;
}

@media screen and (max-width: 800px) {
    .userPage {
        width: unset !important;
    }

    .layout {
        grid-template-columns: unset;
    }

    .page_wrapper {
        padding: 0;
        max-width: 84vw;
    }

    .userPage_text {
        text-align: left;
    }
}
@media screen and (max-width: 1540px) {
    .userPage_label {
        font-size: 20px;
    }
    .userPage_text {
        font-size: 16px;
    }

    .userPage {
        gap: 32px;
    }

    .returnsPage .text {
        font-size: 20px;
        margin-top: 16px;
    }

    .returnsPage .text br {
        margin-top: 16px;
    }
}

@media screen and (max-width: 1200px) {
    .item_bg {
        padding: 0.8rem;
        border-radius: 16px;
    }
}

@media screen and (max-width: 800px) {
    .charts {
        margin-top: 20px;
    }

    .item_bg {
        border-radius: 10px;
    }

    .userPage_avatar {
        width: 76px;
        height: 76px;
    }

    .text_left {
        text-align: left;
    }
}