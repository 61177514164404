.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
}

.items_wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.items_wrapper_alone {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
}

.items_wrapper .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-self: flex-start;
}

.left > span,
form > span {
    text-align: left;
    color: var(--text);
    font-size: 22px;
    font-style: italic;
    line-height: normal;
}

.left > span > a {
    text-decoration: underline;
    color: var(--accent);
}

.left > button,
form.referral > button{
    margin-top: 20px;
    width: 180px;
    border-radius: 10px;
    background: var(--accent);
    border: 0;
    outline: 0;

    color: #FFF;
    text-align: center;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    padding: 6px 30px;
}

.left > button:disabled,
.left > button[disabled]
form.referral > button:disabled{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.left > button:focus,
form.referral > button:focus{
    border: 0;
    outline: 0;
}

.items_wrapper > div:last-child {
    align-self: flex-start;
    justify-self: flex-end;
}

.input {
    margin-top: 10px;
}

.textarea {
    background: revert !important;
    border-radius: 10px !important;
}
.textarea:focus {
    border: #FFF !important;
    box-shadow: none !important;
}

.add_more {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: center;

    color: var(--gray-text);
    font-size: 23px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;

    gap: 6px;

    cursor: pointer;

    margin-top: 20px;
}

.add_more > div {
    background: var(--third-bg);

    border-radius: 7px;

    padding: 3px;
}

.add_more > div > img {
    width: 20px;
    height: 20px;
}

.qr_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.under_qr_text {
    color: #241426;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
}

form.referral {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    width: 100%;
}

@media screen and (max-width: 1024px) {
    .items_wrapper {
        grid-template-columns: 1fr;
        row-gap: 20px;
    }

    .items_wrapper .left {
        max-width: 84vw;
    }
}