.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 260px;
}

.bottom_text {
    width: 100%;

    color: var(--secondary-gray);
    font-size: 10px;
    font-style: italic;
    font-weight: 400;
    line-height: 100%; /* 10px */
    letter-spacing: -0.4px;

    text-align: left;

    padding: 20px 14px 20px 14px;
}

.wrapper_slider {
    width: 100%;
    border-radius: 23px;
    background: #FFF;
    box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.12);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 18px 20px;
    gap: 20px;
}

.wrapper_slider .text_row {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.wrapper_slider .text_row .text {
    color: var(--text);
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 19.575px */
    letter-spacing: -0.392px;

    text-align: left;

    display: flex;
    width: 120px;
}

.wrapper_slider .text_data {
    color: var(--accent);
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 90%;
    letter-spacing: -2.39px;
}

.wrapper_slider .text_data:after {

    content: 'lb';
    margin-left: 2px;
    color: #D537A8;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.392px;
}

.input[type='range'] {
    width: 100%;

    -webkit-appearance: none;
    appearance: none;
    background: linear-gradient(0deg, #FCC188 0%, #7042D2 65.07%, #2E008E 100%);
    cursor: pointer;
    border-radius: 0;
    height: 3px;
    border: none;
    position: relative;
}
/* Removes default focus */
.input[type="range"]:focus {
    outline: none;
}

/******** Chrome, Safari, Opera and Edge Chromium styles ********/
/* slider track */
.input[type="range"]::-webkit-slider-runnable-track {
    height: 8px;
}

/* slider thumb */
.input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -2px;
    background: #600F85;
    border: 2px solid #6541B0;
    height: 12px;
    border-radius: 8px;
    width: 17px;
    z-index: 2000;
}

.input[type="range"]:focus::-webkit-slider-thumb {
    outline: none;
}

/*********** Firefox styles ***********/
/* slider track */
.input[type="range"]::-moz-range-track {
    height: 8px;
}

/* slider thumb */
.input[type="range"]::-moz-range-thumb {
    margin-top: -2px;
    background: white;
    border: 2px solid #6541B0;
    height: 12px;
    border-radius: 8px;
    width: 17px;
    z-index: 2000;
}

.input[type="range"]:focus::-moz-range-thumb{
    outline: none;
}