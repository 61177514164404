.wrapper {
  height: 55px;
  background: var(--secondary-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px 10%;
  width: 100%;
  position: relative;
  z-index: 2;
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
}

.links .loggedIn svg {
  color: #241426;
}

.links > a {
  font-size: 14px;
}

.links > a[active] {
  color: var(--accent);
}

.main {
  gap: 54px;
}

.right {
  gap: 43px;
}

.figure {
  position: absolute;
  left: 0;
  top: 5px;
  z-index: 1;
}

.help {
  display: flex;
  position: absolute;
  top: 143px;
  right: 6%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 18px;
  opacity: 0;
  animation: appear 1s forwards;
  z-index: 2;
}

@keyframes appear {
  to {
    opacity: 1;
  }
}

.help .text {
  color: var(--gray-text);
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.text .blue {
  color: #600f85;
}

.logo {
  cursor: pointer;
}
.right_mobile,
.left_mobile,
.main_mobile {
  display: none;
}

.stop_animation {
  animation-play-state: paused !important;
}

@media screen and (max-width: 1540px) {
  .help .text {
    font-size: 18px;
  }

  .help > button:first-child {
    margin-top: 3px;
  }

  .main {
    gap: 40px;
  }

  .right {
    gap: 33px;
  }

  .logo {
    width: 81px;
    height: 24px;
  }

  .wrapper {
    padding: 18px 3% 16px 3%;
  }
}

@media screen and (max-width: 800px) {
  .wrapper {
    height: 70px;
    background: linear-gradient(270deg, #1d96de 26%, #080267 64%, #9600da 100%);
    align-items: center;
    padding: 20px 8% 17px 8%;
  }
  .right,
  .figure,
  .main,
  .left {
    display: none;
  }

  .right_mobile,
  .main_mobile,
  .left_mobile {
    display: block;
  }

  .main_mobile > img {
    width: 75px;
    height: 26px;
  }
}

.mobile_menu {
  display: none;
  background: var(--text);
}

@media screen and (max-width: 800px) {
  .help {
    display: none;
  }

  .mobile_menu {
    z-index: 2000;
    display: block;
    position: fixed;
    height: 100vh;
    width: 280px;
    transition: 0.4s ease;
    top: 0;
    left: -280px;
  }

  .mobile_menu_opened {
    left: 0;
  }
}
