.indicator {
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  background: var(--secondary-gray);
  border-radius: 50%;
  margin-inline: 5px;
  cursor: pointer;
}

.indicator.active {
  background: var(--help-color);
}

.arrow_left {
  margin-left: 40px;
}

.arrow_right {
  margin-right: 40px;
}

.slide_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  padding-block: 3rem;
}

.slide_item img {
  width: 90vw;
  height: 50vh;
  object-fit: contain;
  cursor: pointer;
}

.slide_item span {
  color: var(--help-color);
  text-align: center;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.bottom_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: -20px;
  margin-left: 40px;
}

.bottom_wrapper > span {
  align-self: flex-start;
  justify-self: center;
  color: #89216c;
  min-height: 24px;
}

.bottom_indicators {
  width: 100%;
  height: 1px;
  background: #021d64;
  position: relative;
  overflow: visible;
}

.bottom_indicator_item {
  height: 3px;
  position: absolute;
  background: #021d64;
  border-radius: 10px;
  bottom: -1px;

  transition: all 1s ease-in-out;
}

.price_chip {
  width: fit-content;
  border-radius: 10px;
  background: var(--accent);
  color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-bottom: -40px;

  cursor: pointer;
}

.price_chip label {
  text-align: center;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.price_chip span {
  text-align: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.header_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.header_info .description {
  color: var(--gray-text);
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.header_info .links {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.header_info .links a:first-child {
  background: var(--accent);
  color: white;
  padding: 6px 10px;
  border-radius: 10px;
}

.header_info .links a:last-child {
  color: var(--accent);
  padding: 6px 10px;
  border-radius: 10px;
}

@media screen and (max-width: 800px) {
  .bottom_wrapper > span {
    margin-top: 20px;
    margin-left: 10px;
  }

  .bottom_wrapper {
    margin-left: 0;
  }
}

@media screen and (max-width: 1540px) {
  .price_chip label {
    font-size: 30px;
  }
  .price_chip span {
    font-size: 16px;
  }
  .header_info .description {
    color: var(--gray-text);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .slide_item span {
    color: var(--help-color);
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
