.button {
    border-radius: 10px;
    border: none;
    outline: none;
    padding: 9px 20px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 21px;
}

.button:focus {
    border: 0;
    outline: 0;
}

.grey {
    background: var(--secondary-gray);
    color: white;
    transition: all 0.3s ease;
}

.grey:hover {
    background: var(--gray-text);
}

button:disabled,
button[disabled]{
    background: var(--secondary-gray) !important;
    color: white !important;
    transition: all 0.3s ease !important;
}


.button_accent {
    background: var(--accent);
    color: white;
    transition: all 0.3s ease;
}

.button_accent:hover {
    background: var(--accent-hover);
}

@media screen and (max-width: 1540px) {
    .button {
        font-size: 18px;
        width: 140px;
        padding: 6px 18px;
    }
}

@media screen and (max-width: 1200px) {
    .button {
        width: 100px;
        padding: 4px 8px;
        font-size: 16px;
    }
}

@media screen and (max-width: 800px) {
    .button {
        font-size: 12px;
        padding: 4px 4px;
        width: 100px;
        border-radius: 8px;
    }
}