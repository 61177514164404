.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.wrapper .header {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 40px;
}

.wrapper .label {
    color: var(--gray-text);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.wrapper .monthChip {
    border-radius: 9px;
    background: #7CA6BC;
    padding: 4px 17px;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.wrapper .dates {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;

    margin-top: 20px;
}

.dates .item {
    border-radius: 10px;
    background: var(--secondary-bg);

    color: #241426;
    text-align: center;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    padding: 5px;

    cursor: pointer;
}

.active_date {
    background: var(--accent) !important;
    color: white !important;
}