.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5% 8% 1% 8%;
  align-self: center;

  max-width: 100vw;
}

.label {
  color: #89216c;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.68px;
  display: flex;
}

.indicator {
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  background: var(--secondary-gray);
  border-radius: 50%;
  margin-inline: 5px;
  cursor: pointer;
}

.indicator.active {
  background: var(--help-color);
}
