.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.wrapper_data {
  height: 55px;
  background: var(--footer);
  z-index: 2;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
}

.wrapper_data a {
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.figure {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;

  font-size: 18px;
  font-weight: 500;
  width: 100%;
}

.text_left {
  text-align: left;
}

.figure > .figure_img {
  width: 100%;
  object-fit: cover;
  min-height: 256px;
}

.figure > div {
  position: absolute;
  padding: 125px 125px 40px 125px;
  left: 0;
  bottom: 55px;

  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: max-content 1fr;
  column-gap: 120px;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.certificate_1_img {
  width: 63px;
  height: 63px;
}

.certificate_2_img {
  width: 73px;
  height: 56px;
}

.pcca_img {
  width: 61px;
  height: 50px;
}

.compounded_usa_img {
  width: 104px;
  height: 64px;
}

.hippa_img {
  width: 114px;
  height: 63px;
}

.legal_img {
  width: 74px;
  height: 63px;
}

/*subscribe input*/

.subscribe_input {
  border-radius: 8px;
  background: #fff;
  padding: 3px 5px 3px 8px;
  display: flex;
  min-width: 280px;
}

.subscribe_input input {
  border: 0;
  outline: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  width: 100%;
}

.subscribe_input button {
  border-radius: 8px;
  background: #7ca6bc;

  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: 2px 7px;
}

.subscribe_input input:focus,
.subscribe_input button:focus {
  border: 0;
  outline: 0;
}

.error {
  text-align: left;
  color: #800000;
  font-weight: 600;
}

@media screen and (max-width: 1240px) {
  .col {
    gap: 8px;
    align-items: flex-start;
    font-size: 14px;
  }

  .logo {
    width: 81px;
    height: 24px;
  }

  .wrapper_data {
    gap: 40px;
  }
  .wrapper_data a {
    font-size: 14px;
  }

  .figure > div {
    padding: 125px 40px 28px 40px;
  }

  .certificate_1_img {
    width: 45px;
    height: 45px;
  }

  .certificate_2_img {
    width: 53px;
    height: 41px;
  }

  .pcca_img {
    width: 44px;
    height: 36px;
  }

  .compounded_usa_img {
    width: 75px;
    height: 45px;
  }

  .hippa_img {
    width: 83px;
    height: 45px;
  }

  .legal_img {
    width: 53px;
    height: 45px;
  }
}
@media screen and (min-width: 1240px) and (max-width: 1540px) {
  .col {
    gap: 8px;
    align-items: flex-start;
    font-size: 14px;
  }

  .logo {
    width: 98px;
    height: 29px;
  }

  .wrapper_data {
    gap: 40px;
  }
  .wrapper_data a {
    font-size: 14px;
  }

  .figure > div {
    padding: 125px 40px 28px 40px;
  }

  .certificate_1_img {
    width: 54px;
    height: 54px;
  }

  .certificate_2_img {
    width: 64px;
    height: 50px;
  }

  .pcca_img {
    width: 53px;
    height: 44px;
  }

  .compounded_usa_img {
    width: 90px;
    height: 54px;
  }

  .hippa_img {
    width: 100px;
    height: 54px;
  }

  .legal_img {
    width: 63px;
    height: 54px;
  }
}

@media screen and (min-width: 1540px) and (max-width: 1740px) {
  .figure > div {
    padding: 125px 60px 28px 60px;
  }
}

@media screen and (max-width: 1000px) {
  .figure > .figure_img {
    height: 256px;
    min-height: unset;
  }

  .figure > div {
    /* grid-template-columns: 0.5fr 1fr; */
    column-gap: 20px;
    padding: 125px 20px 28px 20px;
  }

  .figure > div > .col:is(:last-child) {
    display: none;
  }

  .subscribe_input {
    min-width: 240px;
  }
}

@media screen and (max-width: 700px) {
  .figure > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding-bottom: 0;
  }
}

@media screen and (max-width: 800px) {
  .wrapper {
    display: none;
  }
}

@media screen and (min-width: 1940px) {
  .wrapper {
    margin-top: 500px;
  }
}
