.second_label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 465px;
}

.items_wrapper {
  margin-top: 6px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  column-gap: 100px;
}

.items_wrapper .left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  color: var(--gray-text);
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  gap: 23px;
}

.items_wrapper > .right {
  margin-top: -74px;
}

.items_wrapper .left > .text {
  text-align: left;
  font-style: italic;
  justify-self: center;
}

.items_wrapper .left > .text > a {
  text-decoration: underline;
}

.items_wrapper .left > .text > div {
  margin-top: 10px;
}

.login_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.button {
  color: white;
  padding: 8px;
  gap: 6px;
  width: 100px;

  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  border-radius: 6px;
  background: var(--Button, linear-gradient(106deg, #080267 0%, #9600da 65.07%, #080267 100%));
  font-style: normal;

  display: flex;
  align-items: center;
  justify-content: center;
}

.accent {
  color: var(--accent);
}

.doctors {
  padding: 51px 8% 50px 8% !important;
  max-width: 100vw !important;
  background: #d5cede;
}

.doctors > div:is(:first-child) {
  text-align: left;
  width: 100%;
}

@media screen and (max-width: 1540px) {
  .items_wrapper .left {
    font-size: 18px;
  }
}

@media screen and (max-width: 1000px) {
  .second_label {
    max-width: 390px;
  }
}

@media screen and (max-width: 800px) {
  .items_wrapper {
    margin-top: 0;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .items_wrapper .left {
    margin-top: 0;
  }
  .items_wrapper > .right {
    margin-top: 0;
  }
  .second_label {
    max-width: 465px;
  }
}
