.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
}

.paddings {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 7% 8% 60px 8%;
}

.additional_lable {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 530px;
}

.additional_lable > span > a {
  text-decoration: underline;
}

.wrapper .text {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  text-align: justify;
  gap: 20px;

  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  line-height: 26px;
  color: var(--gray-text);
}

.text > li {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: text-decoration-color 300ms;
  text-decoration: underline 0.1em rgba(76, 130, 194, 0);
}

.text > li::marker {
  content: '';
}

.text > li:hover {
  color: var(--help-color);
  text-decoration-color: rgba(76, 130, 194, 1);
}

.bottom_text {
  font-size: 24px;
  font-style: italic;
  font-weight: 600;
  color: var(--accent);

  margin-top: 40px;
}

@media screen and (max-width: 800px) {
  .wrapper .text {
    align-self: flex-end;
    /* max-width: 79vw; */
  }

  .paddings {
    gap: 20px;
  }
}
