.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  padding: 7% 8% 60px 8%;
}

.form {
  width: 450px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  align-self: center;

  gap: 20px;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  align-self: flex-end;
}

.buttons button {
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 9px 20px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons button:focus {
  border: none;
  outline: none;
}

.back {
  background: var(--secondary-gray);
  color: white;
  transition: all 0.3s ease;
}

.back:hover {
  background: var(--gray-text);
}

.continue {
  background: var(--accent);
  color: white;
  transition: all 0.3s ease;
}

.continue:hover {
  background: var(--accent-hover);
}

.continue:disabled,
.continue[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
/*second step*/

.plitka {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.plitka .row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 15px;
}

.info {
  border-radius: 10px;
  background: var(--accent);
  width: 50px;
  height: 44px;
  line-height: 40px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  user-select: none;
}

.checkbox_text {
  color: #646363;
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: 120%;
}

.checkbox_text a {
  color: var(--help-color);
  text-decoration: underline;
}

.label {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.href {
  color: var(--help-color) !important;
}

@media screen and (max-width: 800px) {
  .form {
    width: 100%;
  }

  .buttons {
    width: 100%;
  }

  .buttons button {
    width: 100%;
  }
}
