.left_menu_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    gap: 40px;
}

.user_info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.user_info > img {
    width: 74px;
    height: 74px;
    object-fit: cover;
    border-radius: 50%;
}

.user_info > span {
    white-space: nowrap;
    color: var(--accent);
    font-size: 28px;
    font-style: normal;
    font-weight: 450;
    line-height: 140%;
    letter-spacing: -0.56px;
}

.links {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    gap: 23px;
}

.links > a {
    width: min-content;
    display: flex;
    align-items: center;
    gap: 10px;

    color: var(--text);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;

    white-space: nowrap;

    position: relative;
}
.links > a[active] {
    color: var(--accent);
}

.links > a[hasNew]:before {
    content: "";
    background: linear-gradient(98deg, #F79E1B 19.62%, #F75D1B 55.06%, #D537A8 84.43%);
    width: 10px;
    height: 10px;
    border-radius: 50%;

    position: absolute;
    left: -3px;
    top: -1px;
}

.sign_out_button {
    border-radius: 7px;
    background: linear-gradient(106deg, #080267 0%, #9600DA 65.07%, #080267 100%);

    padding: 10px 20px;

    color: white;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.18px;

    border: 0;
    outline: 0;
}

.sign_out_button:focus {
    border: 0;
    outline: 0;
}

@media screen and (max-width: 800px) {
    .links {
        display: none;
    }

    .sign_out_button {
        display: none;
    }

    .left_menu_wrapper {
        margin-bottom: 20px;
    }
}