.wrapper {
    border-radius: 20px;
    background: linear-gradient(98deg, #1D96DE 1.48%, #080267 70.12%, #9600DA 97.91%);

    color: white;

    width: 50vw;
    gap: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    padding: 4%;
}

.items_wrapper {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}


.items_wrapper > .link {
    font-size: 32px;
    font-weight: 600;
    color: var(--accent);
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 20px;
}

.item > button {
    border: 0;
    outline: 0;
    border-radius: 10px;
    background: var(--accent);

    width: 50%;

    color: #FFF;
    text-align: center;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    padding: 8px 0;
}

.item_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: #FFF;

    aspect-ratio: 1/1;
    flex-shrink: 0;
    padding: 20px;

    color: var(--text);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.item_inner .text_top {
    font-size: 74px;
}
.item_inner .text_bottom {
    font-size: 32px;
}

.label {
    color: #FFF;
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.disclaimer {
    width: 80%;
    color: var(--third-bg);
    text-align: center;
    font-weight: 400;
    line-height: normal;
    font-size: 32px;
    font-style: italic;
}

@media screen and (max-width: 1540px) {

    .label {
        font-size: 40px;
    }

    .disclaimer {
        font-size: 20px;
    }

}

@media screen and (max-width: 800px) {
    .wrapper {
        width: 84vw;
    }

    .label {
        font-size: 32px;
    }

    .disclaimer {
        font-size: 18px;
    }
}