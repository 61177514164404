.form_wrapper {
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
}

.form_wrapper > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.label {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    align-self: flex-start;
}

.dropdown {
    width: 50% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.dropdown > div:first-child {
    width: 100% !important;
    justify-content: space-between;
}

.checkboxes_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}

.checkboxes_wrapper_cols {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
    row-gap: 20px;
}

.checkboxes_wrapper_cols > div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}

.checkboxes_wrapper > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.checkboxes_wrapper_cols > div > span,
.checkboxes_wrapper > div > span {
    margin-top: 4px;
    text-align: left;
    max-width: calc(100% - 41px);
}
.checkboxes_wrapper_cols > div > label,
.checkboxes_wrapper > div > label {
    width: 31px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    background: var(--third-bg);
    color: var(--gray-text);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    user-select: none;

    border-radius: 10px;
    position: relative;

    cursor: pointer;
}

.checkboxes_wrapper_cols > div > label > input[type='checkbox'],
.checkboxes_wrapper > div > label > input[type='checkbox'] {
    accent-color: var(--accent);
    border: 0;
    outline: 0;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 3px;
    right: 3px;
    height: 25px;
    width: 25px;
    border-radius: 10px;
}

.checkboxes_wrapper_cols > div > label > input[type='checkbox']:checked ~ .checkmark,
.checkboxes_wrapper > div > label > input[type='checkbox']:checked ~ .checkmark {
    background-color: transparent;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkboxes_wrapper_cols > div > label > input[type='checkbox']:checked ~ .checkmark:after,
.checkboxes_wrapper > div > label > input[type='checkbox']:checked ~ .checkmark:after {
    display: block;
}

.checkmark:after {
    left: 11px;
    top: 6px;
    width: 5px;
    height: 10px;
    border: solid var(--accent);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.text {
    background: var(--third-bg);
    color: var(--gray-text);
    border-radius: 10px;
    padding: 10px;
}

.text:focus {
    border: 0;
    outline: 0;
}

.text::-webkit-outer-spin-button,
.text::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.text[type=number] {
    -moz-appearance: textfield; /* Firefox */
}

.error {
    text-align: left;
    color: #800000;
    font-weight: 600;
}

@media screen and (max-width: 600px) {

    .checkboxes_wrapper_cols {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
    }
}