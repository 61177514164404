.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    padding: 7% 8% 60px 8%;
}

/*result schedule*/

.wrapper_data {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
    position: relative;
}



.wrapper_data > img {
    width: 25%;
}

.wrapper_data > p {
    width: 20%;
    text-align: left;
    color: var(--gray-text);
    font-size: 18px;
}

.wrapper_data > p.label_text {
    width: 50%;
    font-size: 32px;
    font-weight: 800;
}

.wrapper_data > p.label_body {
    width: 50%;
    font-size: 24px;
}

.wrapper_data > p > span {
    color: var(--help-color);
}

.wrapper_order {
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
    position: relative;
    margin: 0 auto;
}

.wrapper_order > .order_label {
    font-size: 32px;
    font-weight: 800;
    color: var(--accent) !important;
    text-align: left;
}

.wrapper_order > .order_body {
    font-size: 32px;
    font-weight: 800;
    text-align: left;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    align-self: center;
}

.buttons button {
    border-radius: 10px;
    border: none;
    outline: none;
    padding: 9px 20px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttons button:focus {
    border: none;
    outline: none;
}

.back {
    background: var(--secondary-gray);
    color: white;
    transition: all 0.3s ease;
}

.back:hover {
    background: var(--gray-text);
}

.continue {
    background: var(--accent);
    color: white;
    transition: all 0.3s ease;
}

.continue:hover {
    background: var(--accent-hover);
}

@media screen and (max-width: 800px) {
    .wrapper_data {
        width: auto;
    }

    .wrapper_data > p.label_text,
    .wrapper_data > p.label_body {
        width: 100%;
    }
}
