.wrapper {
    display: none;
}

@media screen and (max-width: 800px) {
    .wrapper {
        position: fixed;
        bottom: 0;
        display: flex;
        width: 100%;
        height: 73px;
        background: var(--third-bg);

        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        padding: 28px 25px 25px 25px;

        z-index: 11;
    }
}