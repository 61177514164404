.table {
    width: 100%;
    border-spacing: 0 10px;
    border-collapse: separate;
}

.table thead,
.table tbody {
    width: 100%;
}

.table tbody {
    display: table-row-group;
    gap: 10px;
}

.table thead {
    color: var(--gray-text);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    text-align: left;
}

.table thead span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: fit-content;

    cursor: pointer;
}

.table_row {
    border-radius: 10px;
    padding-bottom: 11px;
}

.table_row td {
    margin: 0 !important;
    padding-block: 18px;
    background: #FFF;

    color: #2C2C2C;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    text-align: left;
}

.table_row td:first-child {
    border-radius: 10px 0 0 10px;
    padding-left: 18px;
}

.table_row td:last-child {
    border-radius: 0 10px 10px 0;
    padding-right: 18px;
}

.status_label_wrapper {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.status_label {
    display: flex;
    width: fit-content;
    padding: 4px 22px;
    justify-content: center;
    align-items: center;

    border-radius: 30px;

    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.status_label_green {
    background: #E1FFDC;
    color: #07A104;
}
.status_label_red {
    background: #ffdcdc;
    color: #a10409;
}
.status_label_yellow {
    background: #FFF5DC;
    color: #E2B102;
}

/*text*/

.top_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}

.label {
    color: #2C2C2C;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.description {
    color: var(--gray-text);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.download_button {
    outline: 0;
    border: 0;

    display: flex;
    padding: 8px 11px;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
    background: #89216C;

    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.download_button:focus {
    outline: 0;
    border: 0;
}

@media screen and (max-width: 800px) {
    .top_wrapper {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    .table_row td:last-child {
        float: unset;
        display: table-cell;
    }

    .table_scroll {}
}