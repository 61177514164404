.modal {
    bottom: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
    animation: appear 0.3s forwards;
}

.modal:focus {
    border: 0;
    outline: 0;
}

@keyframes appear {
    from {opacity: 0}
    to {opacity: 1}
}