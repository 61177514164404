.help_section {
    width: 100%;
    display: grid !important;
    grid-template-columns: 1fr auto !important;

    gap: 65px !important;
}

@media screen and (max-width: 800px) {
    .help_section {
        grid-template-columns: 1fr !important;
    }
}

.help_section > div:is(:last-child) {
    max-width: 470px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    gap: 20px;
}

.help_section > div > p {
    color: var(--secondary-gray);
    text-align: left;

    font-style: italic;
    font-size: 22px;
    font-weight: 450;
    line-height: 35.9px;
}



.help_section > img {
    object-fit: contain;
}

/*subscribe input*/

.subscribe_input {
    border-radius: 8px;
    background: #FFF;
    padding: 3px 5px 3px 8px;
    display: flex;
    min-width: 300px;
}

.subscribe_input input {
    border: 0;
    outline: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    width: 100%;
}

.subscribe_input button {
    border-radius: 8px;
    background: #7CA6BC;

    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding: 2px 7px;
}

.subscribe_input input:focus,
.subscribe_input button:focus {
    border: 0;
    outline: 0;
}

.error {
    text-align: left;
    color: #800000;
    font-weight: 600;
}