.wrapper {
    width: fit-content;
    height: fit-content;
    position: relative;
}

.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    aspect-ratio: 1/1;

    background: var(--accent);
    color: white;
    padding: 4px;

    position: absolute;
    right: -15px;
    top: -15px;

    font-size: 12px;
}