.mobile_menu {
    display: none;
    background: var(--text);

    --color: #F3F1F1;
}

.background {
    display: none;
}

.logo {
    width: 56px;
    height: 20px;
}

.avatar {
    width: 75px;
    height: 75px;
    object-fit: cover;
    border-radius: 50%;
}

.hide {
    opacity: 0;
}

.profile_data {
    width: 100%;
    display: grid;
    grid-template-columns: 75px auto;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 25px;

    color: var(--color);

    gap: 13px;
}

.profile_data .avatar_container {
    position: relative;
    width: 75px;
    height: 75px;
}

.profile_data .avatar_container > img,
.profile_data .avatar_container > div {
    position: absolute;
    left: 0;
    top: 0;
}


.profile_data > div {
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;

    height: 100%;

    gap: 5px;
}

.profile_data > div > span {
    max-width: 146px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.profile_data > div > span:is(:first-child) {
    font-size: 18px;
}

.profile_data > div > span:is(:last-child) {
    color: var(--help-color);
    margin-top: auto;
    cursor: pointer;
}

.mobile_menu .top {
    padding: 48px 33px 30px 33px;
}

.top .col {
    margin-top: 10px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    color: var(--help-color);
    font-size: 12px;
}

.col .error {
    color: #900;
}

.top .col .row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.mobile_menu .bottom {
    background: var(--color);

    height: 100%;

    padding: 10px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.mobile_menu .bottom .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.mobile_menu .bottom .list .divider {
    width: calc(100% - 60px);
    border-bottom: 1px solid black;
    height: 0;
    align-self: center;

    margin-bottom: 4px;
}

.mobile_menu .bottom .menu_link {
    color: var(--text);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;

    padding: 7px 30px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    gap: 15px;
    width: 100%;
}

.mobile_menu .bottom .menu_link_disabled {
    opacity: 0.6;
}

.mobile_menu .bottom .menu_link > svg {
    width: 24px;
    height: 24px;
}

.mobile_menu .bottom .list > a[active] {
    background: #D7DADB;
}

@keyframes background {
    from {background: transparent}
    to {background: rgba(0, 0, 0, 0.30)}
}

@media screen and (max-width: 800px) {

    .background {
        display: block;
        position: fixed;
        min-height: 100vh;
        width: 100vw;

        z-index: 1999;

        animation: background 0.4s ease forwards;
    }

    .mobile_menu {
        z-index: 2000;
        display: block;
        position: fixed;
        height: 100vh;
        width: 300px;
        transition: 0.4s ease;
        top: 0;
        left: -300px;
    }

    .mobile_menu_opened {
        left: 0;
    }
}


