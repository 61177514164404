.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    padding: 7% 8% 60px 8%;
}

.items_wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    row-gap: 20px;
    padding: 0 5rem;
}

.item {
    background: var(--third-bg);
    color: #241426;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: grid;
    place-content: center;
    border-radius: 10px;
    cursor: pointer;
    padding: 16px;
}

.item_active {
    background: var(--accent) !important;
    color: white !important;
}

/*result schedule*/

.wrapper_data {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
    position: relative;
}



.wrapper_data > img {
    width: 25%;
}

.wrapper_data > p {
    width: 20%;
    text-align: left;
    color: var(--gray-text);
    font-size: 18px;
}

.wrapper_data > p.label_text {
    width: 50%;
    font-size: 32px;
    font-weight: 800;
}

.wrapper_data > p.label_body {
    width: 50%;
    font-size: 24px;
}

.wrapper_data > p > span {
    color: var(--help-color);
}

.wrapper_order {
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
    position: relative;
    margin: 0 auto;
}

.wrapper_order > .order_label {
    font-size: 32px;
    font-weight: 800;
    color: var(--accent) !important;
    text-align: left;
}

.wrapper_order > .order_body {
    font-size: 32px;
    font-weight: 800;
    text-align: left;
}

.disclaimer_text {
    max-width: 50%;
    display: flex;
    color: #D537A8;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;

    text-align: left;

    font-style: italic;
}

.buttons {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    align-self: center;
}

.buttons button {
    border-radius: 10px;
    border: none;
    outline: none;
    padding: 9px 20px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttons button:focus {
    border: none;
    outline: none;
}

.back {
    background: var(--secondary-gray);
    color: white;
    transition: all 0.3s ease;
}

.back:hover {
    background: var(--gray-text);
}

.continue {
    background: var(--accent);
    color: white;
    transition: all 0.3s ease;
}

.continue:hover {
    background: var(--accent-hover);
}

/*add to calendar*/

.wrapper_data_add_to_calendar {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 200px;
    margin-bottom: -160px;
}

.wrapper_data_add_to_calendar img {
    cursor: pointer;
}

.wrapper_data_add_to_calendar > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wrapper_data_add_to_calendar > div > div {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.wrapper_data_add_to_calendar > div > div > img:last-child {
    margin-top: 10px;
}

.wrapper_data_add_to_calendar > div > p {
    color: var(--gray-text);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.skip_button {
    justify-self: center;
    border-radius: 10px;
    color: white;
    background: var(--secondary-gray);
    padding: 8px 12px;
    width: 160px;
}

/*payment*/

.payment_wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4%;
}

.payment_part_column {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 4rem;
}

.pencil_wrapper {
    width: 100%;
    padding: 10% 10% 6% 10%;
    background: var(--secondary-bg);
    border-radius: 10px;
}

.payment_item_text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.payment_item_text p {
    color: var(--color-ui-2, #1B2028);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.payment_item_text span {
    color: var(--gray-text-secondary);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.payment_disclaimer {
    color: var(--gray-text);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
}

.payment_disclaimer a {
    color: var(--help-color);
}

.payment_left_part > div:first-child {
    display: flex;
}

.payment_left_part > form > div {
    border-bottom: 1px solid var(--third-bg);
    width: 100%;
    display: grid;
    grid-template-columns: 43px auto 60px;
    padding-block: 20px;
    padding-inline: 30px;
    cursor: pointer;
}

.payment_left_part > div {
    border-bottom: 1px solid var(--third-bg);
    width: 100%;
    display: grid;
    grid-template-columns: 43px auto 60px;
    padding-block: 20px;
    padding-inline: 30px;
    cursor: pointer;
}

.payment_left_part > form > div > img:last-child,
.payment_left_part > div > img:last-child {
    align-self: center;
}

.payment_method_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.payment_method_name {
    color: #171717;
    font-size: 29px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.733px;
}

.payment_method_additional_data {
    color: #171717;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: -0.366px;
    opacity: 0.6;
}

.credit_card_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.credit_card_block > div:first-child {
    width: 100%;
    display: grid;
    grid-template-columns: 40px auto 40px;
    column-gap: 1rem;
}

.credit_card_block > div:first-child > p {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
}

.credit_card_block_card_wrapper {
    width: 100%;
    height: 270px;
    display: grid;
    grid-template-columns: 60px auto;
    column-gap: 4%;
    align-items: center;
}

.credit_card_block_card_wrapper .add_button {
    border-radius: 30px;
    height: 75%;
    display: grid;
    place-content: center;
    background: #F3F6F8;
    padding: 10px;
}

.credit_card_block_card_wrapper .card_wrapper {
    position: relative;
    width: calc(100% - 20px);
    border-radius: 43px;
    height: 100%;
}
.credit_card_block_card_wrapper .card_wrapper .card {
    background: #171717;
    width: 100%;
    height: 100%;
    border-radius: 43px;
    position: absolute;
    z-index: 103;
}

.card_back {
    width: 100%;
    position: absolute;
    border-radius: inherit;
    top: 50%;
    transform: translate(0, -50%);
}

/*pick date*/

.pick_date_wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1.4fr;
    grid-template-rows: 1fr;
}

.pick_date_wrapper .time {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4rem;
}

.pick_date_wrapper .time span {
    color: var(--gray-text);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.footer_wrapper {
    width: unset;
    flex-direction: row;
    position: absolute;
    right: 0;
    bottom: -140px;
}

.form_wrapper > div:is(:nth-child(3)) {
    display: none;
}

@media screen and (max-width: 1540px) {
    .payment_method_name{
        font-size: 20px;
        line-height: 30px;
    }

    .footer_wrapper {
        position: relative;
        flex-direction: row !important;
        right: unset;
        bottom: unset;
    }

    .disclaimer_text {
        max-width: 100%;
    }

    .payment_method_additional_data {
        font-size: 16px;
        line-height: 20px;
    }

    .payment_left_part > div > img:first-child {
        width: 30px;
        height: 30px;
    }
    .payment_left_part > div > img:last-child {
        width: 40px;
        height: 40px;
    }
    .credit_card_block > div > img,
    .credit_card_block > div > svg {
        width: 40px;
        height: 40px;
    }
}