.wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly !important;
    flex-direction: row !important;
}

.divider {
    display: block;
    height: 90%;
    width: 2px;
    background: var(--secondry-gradient-row);
}

.accent_color {
    color: var(--accent);
}

.gray {
    color: var(--secondary-gray);
}

.black {
    color: var(--text);
}

.weight_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 16px;
}

.weight_block > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.weight_block .big_text {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 650;
    line-height: 90%;
    letter-spacing: -0.961px;
    margin-right: 2px;
    height: 20px;
    display: inline-block;
}

.weight_block .small_text {
    text-align: center;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.174px;
    line-height: 140%;
}


.current_weight_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.current_weight_block .big_text {
    text-align: center;
    font-size: 66px;
    font-style: normal;
    font-weight: 650;
    line-height: 90%;
    height: 20px;
    letter-spacing: -2.667px;
}

.current_weight_block .small_text {
    text-align: center;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.375px;
}


.next_task_block {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.next_task_block .label {
    color: #241426;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.246px;
}

.next_task_block .text {
    color: #000;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.214px;
}

.achievements {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    gap: 10px;
}

.achievements .row {
    display: grid;
    grid-template-columns: 22px auto;
    gap: 10px;
}

.achievements .row .amount {
    color: #89216C;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-right: 4px;
}

.achievements .row .text {
    color: #241426;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.graph {
    width: 140px;
    height: 140px;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
}

.graph_wrapper {
    display: grid;
    place-content: center;
    position: relative;
    justify-items: center;
    width: 120px;
    height: 120px;

    border-radius: 50%;
    background: linear-gradient(130deg, #F79E1B 0%, #F75D1B 17%, #D537A8 25%, #FCC188 35%, #7042D2 50%, #7042D2 70%, #2E008E 100%);
}

/* .graph_wrapper:before {
    position: absolute;
    content: '';
    width: 6px;
    height: 20px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background: white;
} */

.graph_wrapper_bg {
    position: absolute;
    content: '';
    background: white;
    border-radius: inherit;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 100px;
    height: 100px;
    z-index: 1;
}

.graph_wrapper .amount {
    text-align: center;
    font-size: 50px;
    font-style: normal;
    font-weight: 650;
    line-height: 90%;
    letter-spacing: -1.995px;
}

.graph_wrapper .amount_small_text {
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 650;
    line-height: 140%;
    letter-spacing: -0.242px;
}

.graph_wrapper .text {
    text-align: center;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.173px;
    margin-bottom: 2px;
    z-index: 2;
}

.graph_wrapper .indicator_wrapper {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.graph_wrapper .indicator_wrapper .indicator {
    position: absolute;
    z-index: 200;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 10px;
    width: 14px;
    height: 10px;
    background: white;
    border: 2px solid #7042D2;
}

@media screen and (max-width: 1540px) {
    .wrapper {
        justify-content: space-between !important;
    }
}

@media screen and (max-width: 1300px) {
    .graph {
        width: 120px;
        height: 120px;
    }

    .graph_wrapper {
        width: 120px;
        height: 120px;
    }

    .current_weight_block .big_text {
        font-size: 50px;
    }

    .current_weight_block .small_text {
        font-size: 16px;
    }

    .weight_block .big_text {
        font-size: 20px;
    }

    .achievements .row {
        gap: 6px;
    }
}

@media screen and (max-width: 1200px) {
    .wrapper {
        flex-wrap: wrap;
        gap: 20px;
        justify-content: space-evenly !important;
    }

    .divider {
        display: none;
    }

    .next_task_block {
        height: auto;
        justify-content: center;
        gap: 10px;
    }
}