.wrapper {
    width: 100%;
    position: relative;
    border-radius: 10px;
    /* overflow: hidden; */
    aspect-ratio: 386/255;

    background: url(/public/imageCardBg.png) center/cover no-repeat;
}

.wrapper img {
    width: 100%;
    /* height: 100%; */
    /* object-fit: cover; */
    min-height: 100%;
    border-radius: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    object-fit: cover;
}

.wrapper .text {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), linear-gradient(90deg, #1D96DE 0%, #080267 52%, #9600DA 100%);
    padding: 9px 23px 12px 23px;

    color: white;
    font-weight: 600;
    font-size: 12px;

    text-align: center;
    text-wrap: nowrap;
    white-space: pre;
    overflow-x: hidden;
    text-overflow: ellipsis;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}