.all_slides {
  display: flex;
  width: 88%;
  /*height: 100vh;*/
  position: relative;
  /*align-items: flex-end;*/
}

.single_slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  transition: 2s ease;
  border-radius: 15px;
  box-shadow: 0 6px 12px -2px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  z-index: 200;
}

.image_wrapper {
  width: 100%;
  height: 180px;
  overflow: hidden;
  display: grid;
  place-content: center;
  padding-top: 20px;
}

.image_wrapper img {
  width: 100%;
  height: 180px;
  overflow: hidden;
  object-fit: contain;
}

/* Left slide*/
/*.single_slide[data-order="1"] {*/
/*    left: 0.5%;*/
/*    !*transform: scale(0.8, 0.8);*!*/
/*    z-index: 1;*/
/*    !*opacity: 0.7;*!*/
/*    background: var(--secondary-bg);*/
/*}*/

/*!* Middle slide *!*/
/*.single_slide[data-order="2"] {*/
/*    left: 33.5%;*/
/*    z-index: 3;*/
/*    opacity: 1;*/
/*    background: white;*/
/*}*/

/*!* Right slide*!*/
/*.single_slide[data-order="3"] {*/
/*    left: 66.5%;*/
/*    !*transform: scale(0.8, 0.8);*!*/
/*    z-index: 2;*/
/*    !*opacity: 0.7;*!*/
/*    background: var(--secondary-bg);*/
/*}*/

/*.single_slide:nth-child(2) {*/
/*    order: 3;*/
/*}*/

/*.single_slide:nth-child(1) {*/
/*    order: 2;*/
/*}*/

/*.single_slide:nth-child(3) {*/
/*    order: 1;*/
/*}*/

/*slide data*/

.data_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 42px;
  gap: 42px;
}

.name {
  color: #252b42;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.1px;
}

.description {
  color: #737373;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.1px;
}

.features_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 15px;

  color: var(--text);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.features_wrapper > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 10px;
}

.round {
  display: flex;
  width: 33px;
  height: 33px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  background: #aeaeae;
  color: #241426;

  transition: all 2s ease;
}

.single_slide[data-order='1'] .round,
.single_slide[data-order='3'] .round {
  color: white !important;
}

.color_round {
  background: linear-gradient(98deg, #f79e1b 19.62%, #f75d1b 55.06%, #d537a8 84.43%);
  color: white;
}

.price_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  color: #d537a8;
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0.2px;

  gap: 6px;
}

.price_wrapper .rightCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.price_wrapper .rightCol .dollar {
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.1px;
}

.price_wrapper .rightCol .perMonth {
  color: #89216c;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.left_tumbler {
  z-index: 200;

  cursor: pointer;
}

.right_tumbler {
  transform: scale(-1, 1);
  z-index: 200;

  cursor: pointer;
}

.button {
  border: 1px solid var(--accent);
  border-radius: 5px;
  font-size: 10px;
  font-weight: 600;

  transition: 0.3s ease;

  padding: 7px 14px;
  width: 105px;

  outline: 0;
}

.button:disabled {
  border: 1px solid transparent;
  cursor: not-allowed;
}

.button:focus {
  outline: 0;
}

.button[variant='contain'] {
  background: var(--accent);
  color: white;

  &:hover {
    background: #89216c;
  }
}

.button[variant='outline'] {
  background: white;
  color: var(--accent);

  &:hover {
    color: white;
    background: var(--accent);
  }
}

.price_label {
  color: var(--icon-hover);
}

.cross {
  text-decoration: none;
  position: relative;

  color: var(--icon-hover);
}

.cross:before {
  content: '';
  border-bottom: 2px solid var(--icon-hover);
  position: absolute;
  width: 100%;
  height: 50%;
  margin-left: -4px;
  transform: rotate(-12deg);
  transition: border-bottom 0.3s ease;
}

.insert_text li {
  text-align: left;
}

.insert_text ul {
  padding-left: 15px;
}

@media screen and (max-width: 1540px) {
  .data_wrapper {
    padding: 28px;
    gap: 28px;
  }

  .features_wrapper {
    gap: 10px;
  }

  .price_wrapper {
    font-size: 42px;
  }
}

@media screen and (max-width: 800px) {
  .single_slide {
    overflow: visible;
  }

  .rs_slider_wrapper {
    overflow: visible;
  }

  .data_wrapper {
    gap: 16px;
  }
}

/*rsslider*/

.rs_slider_wrapper {
  display: flex;
  overflow: hidden;
}

.bottom_indicators {
  width: 100%;
  height: 1px;
  background: #021d64;
  position: relative;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom_indicators .invisible_item {
  width: 100%;
  cursor: pointer;
  height: 20px;
}

.bottom_indicator_item {
  height: 3px;
  position: absolute;
  background: #021d64;
  border-radius: 10px;
  bottom: -1px;

  transition: all 0.8s ease-in-out;
}
